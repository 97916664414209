<div [ngSwitch]="dataType">
  <div *ngSwitchCase="'mosaico'">
    <div>
      <h2 class="text-center text-4xl font-bold">MÓDULO GENERADOR DE MOSAICO</h2>
      <p class="text-2xl">
        La Autoridad Plurinacional de la Madre Tierra ha desarrollado una herramienta en Google Earth Engine para generar mosaicos basados en algoritmos, destinada al monitoreo de bofedales en la región Altoandina. Para utilizar esta herramienta, sigue los siguientes pasos:
      </p>
      <ul class="text-2xl">
        <li>
          Primero, el usuario debe registrarse en la Plataforma Google Earth Engine a través del siguiente enlace:
          <a href="https://earthengine.google.com" target="_blank">https://earthengine.google.com</a>. Es necesario
          tener una cuenta en Gmail. Una vez realizado el registro, el usuario recibirá un correo de confirmación para
          acceder a la plataforma.
        </li>
        <li>
          Una vez registrado en Google Earth Engine, se podrá ingresar al script con el siguiente enlace: <br />
          <a href="https://code.earthengine.google.com/975aef7bcd628bd198ee8438cbc0700f" target="_blank">
						https://code.earthengine.google.com/975aef7bcd628bd198ee8438cbc0700f
					</a>
        </li>
      </ul>

      <img class="w-full" src="assets/mosaico.png" alt="mosaico" />
    </div>
  </div>
  <div *ngSwitchCase="'clasificador'">
    <div>
      <h2 class="text-center">MÓDULO CLASIFICADOR</h2>
      <p class="text-2xl">
        La Autoridad Plurinacional de la Madre Tierra ha desarrollado una herramienta que utiliza algoritmos para clasificar cinco tipos de coberturas (Bofedales; Lagos, Lagunas y Cursos de Agua; Salares; Nevados y Otras Coberturas), con el objetivo de monitorear los bofedales en la región Altoandina. Para utilizar esta herramienta, sigue los siguientes pasos:
      </p>
      <ul class="text-2xl">
        <li>
          Primero, el usuario debe registrarse en la Plataforma Google Earth Engine a través del siguiente enlace:
          <a href="https://earthengine.google.com" target="_blank">https://earthengine.google.com</a>. Es necesario
          tener una cuenta en Gmail. Una vez realizado el registro, el usuario recibirá un correo de confirmación para
          acceder a la plataforma.
          <br />
          <strong>Nota:</strong> Para generar la clasificación se debe tener disponible un mosaico para tomar los puntos
          de muestreo.
        </li>
        <li>
          Después de generar el mosaico, el usuario podrá acceder al Clasificador mediante el siguiente enlace: <br />
          <a href="https://code.earthengine.google.com/773d3eb9940115ec59f94db5d8631079" target="_blank">
						https://code.earthengine.google.com/773d3eb9940115ec59f94db5d8631079
					</a>
        </li>
      </ul>

      <img class="w-full" src="assets/clasificador.jpeg" alt="clasificador" />
    </div>
  </div>
</div>
