import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminTemplateComponent } from './templates/admin-template/admin-template.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { NotfoundComponent } from './shared/pages/notfound/notfound.component';
import { SystemPermissionGuard } from './shared/guards/system-permission.guard';
import { Resource } from './shared/constants';
// verifyPermission comes from permissionTypes review it to set a value
const routes: Routes = [
	{
		path: '',
		redirectTo: 'auth',
		pathMatch: 'full',
	},
	{ path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) },
	{ path: 'error', loadChildren: () => import('./shared/pages/error/error.module').then((m) => m.ErrorModule) },
	{ path: 'access', loadChildren: () => import('./shared/pages/access/access.module').then((m) => m.AccessModule) },
	{
		path: '',
		component: AdminTemplateComponent,
		canActivate: [AuthenticationGuard],
		canActivateChild: [SystemPermissionGuard],
		children: [
			{
				path: 'home',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'user',
				data: {
					moduleNameCode: 'USUARIOS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
			},
			{
				path: 'documents',
				data: {
					moduleNameCode: 'GESTION_DOCUMENTOS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
			},
			{
				path: 'role',
				data: {
					moduleNameCode: 'ROLES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/role/role.module').then((m) => m.RoleModule),
			},
			{
				path: 'system-acl',
				data: {
					moduleNameCode: 'PERMISOS_SISTEMA',
					verifyPermission: 'canView',
				},
				loadChildren: () =>
					import('./modules/permiso-sistema/permiso-sistema.module').then((m) => m.PermisoSistemaModule),
			},
			{
				path: 'log-activity',
				data: {
					moduleNameCode: 'LOG_ACTIVIDADES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/log-actividad/log-actividad.module').then((m) => m.LogActividadModule),
			},
			{
				path: 'type-of-plan',
				data: {
					moduleNameCode: 'TIPO_PLANES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/tipo-plan/tipo-plan.module').then((m) => m.TipoPlanModule),
			},
			{
				path: 'plans',
				data: {
					moduleNameCode: 'PLANES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/planes/planes.module').then((m) => m.PlanesModule),
			},
			{
				path: 'ambit',
				data: {
					moduleNameCode: 'AMBITOS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/ambitos/ambitos.module').then((m) => m.AmbitosModule),
			},
			{
				path: 'sector',
				data: {
					moduleNameCode: 'SECTORES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/sectores/sectores.module').then((m) => m.SectoresModule),
			},
			{
				path: 'category',
				data: {
					moduleNameCode: 'CATEGORIAS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/categorias/categorias.module').then((m) => m.CategoriasModule),
			},
			{
				path: 'standards',
				data: {
					moduleNameCode: 'ESTANDARES',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/standards/standards.module').then((m) => m.StandardsModule),
			},
			{
				path: 'documents',
				data: {
					moduleNameCode: 'ADMINISTRACION_DOCUMENTOS_MENU',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
			},
			{
				path: 'information-resource-type',
				data: {
					moduleNameCode: 'RECURSOS_INFORMACION',
					verifyPermission: 'canView',
				},
				loadChildren: () =>
					import('./modules/information-resource-type/information-resource-type.module').then(
						(m) => m.InformationResourceTypeModule
					),
			},
			{
				path: 'information-resource',
				data: {
					moduleNameCode: 'RECURSOS_INFORMACION',
					verifyPermission: 'canView',
				},
				loadChildren: () =>
					import('./modules/information-resource/information-resource.module').then((m) => m.InformationResourceModule),
			},
			{
				path: 'web-map-service',
				data: {
					moduleNameCode: 'SERVICIOS_MAPAS_WEB',
					verifyPermission: 'canView',
				},
				loadChildren: () =>
					import('./modules/web-map-service/web-map-service.module').then((m) => m.WebMapServiceModule),
			},
			{
				path: 'coverages',
				data: {
					moduleNameCode: 'RECURSOS_CARTOGRAFICOS',
					verifyPermission: 'canView',
				},
				loadChildren: () =>
					import('./modules/cartographic-resource/cartographic-resource.module').then(
						(m) => m.CartographicResourceModule
					),
			},
			{
				path: 'thematics',
				data: {
					moduleNameCode: 'TEMATICAS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/thematic/thematic.module').then((m) => m.ThematicModule),
			},
			{
				path: 'sld',
				data: {
					moduleNameCode: 'RECURSOS_CARTOGRAFICOS',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/sld/sld.module').then((m) => m.SldModule),
			},
			{
				path: 'dashboards',
				data: {
					moduleNameCode: Resource.DASHBOARDS,
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
			},
			{
				path: 'big-data',
				loadChildren: () => import('./modules/big-data/big-data.module').then((m) => m.BigDataModule),
			},
			{
				path: 'big-data/reports',
				loadChildren: () => import('./modules/reportes/reportes.module').then((m) => m.ReportesModule),
			},
			{
				path: 'components',
				data: {
					moduleNameCode: Resource.ENV_COMPONENTS,
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/env-components/env-components.module').then((m) => m.EnvComponentsModule),
			},
			{
				path: 'bofedales',
				data: {
					link: 'https://ee-apmtmadretierra.projects.earthengine.app/view/monitoreo-de-bofedales',
				},
				loadComponent: () => import('./modules/frame-view/frame-view.component').then((m) => m.FrameViewComponent),
			},

			{
				path: 'mosaico',
				data: {
					dataType: 'mosaico',
				},
				loadComponent: () => import('./modules/simple-img/simple-img.component').then((m) => m.SimpleImgComponent),
			},
			{
				path: 'clasificador',
				data: {
					dataType: 'clasificador',
				},
				loadComponent: () => import('./modules/simple-img/simple-img.component').then((m) => m.SimpleImgComponent),
			},
		],
	},
	{
		path: 'transparencia',
		loadChildren: () =>
			import('./modules/transparencia-public/transparencia-public.module').then((m) => m.TransparenciaPublicModule),
	},
	{
		path: 'internal',
		canActivate: [AuthenticationGuard],
		canActivateChild: [SystemPermissionGuard],
		children: [
			{
				path: 'geocambioclimatico',
				data: {
					moduleNameCode: 'GEOVISOR',
					verifyPermission: 'canView',
				},
				loadChildren: () => import('./modules/geovisor/geovisor.module').then((m) => m.GeovisorModule),
			},
		],
	},
	{
		path: 'geocambioclimatico',
		loadChildren: () => import('./modules/geovisor/geovisor.module').then((m) => m.GeovisorModule),
	},
	{ path: 'public', loadChildren: () => import('./public/public.module').then((m) => m.PublicModule) },
	{ path: 'notfound', component: NotfoundComponent },
	{ path: '**', component: NotfoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
