<h2 class="text-center text-4xl font-bold">GEOVISOR PARA EL MONITOREO DE BOFEDALES</h2>

<p class="text-2xl">
  La Autoridad Plurinacional de la Madre Tierra (APMT) ha desarrollado un Geovisor para el monitoreo de Bofedales
  basadas en algoritmos de aprendizaje mediante la plataforma de Google Earth Engine.
  <br />
  En el geovisor se encuentra disponible información para la región Altoandina, generada a partir de la colección de imágenes Sentinel-2, utilizando la siguiente temporalidad:
</p>

<ul class="text-2xl">
	<li>Mes de inicio <b>08</b>, Mes final <b>11</b> y año <b>2022</b>.</li>
</ul>

<p class="text-2xl">A continuación, se presenta el aplicativo desarrollado.</p>

<iframe *ngIf="link !== null" [src]="safeLink" class="w-full" sandbox="allow-same-origin allow-scripts"></iframe>
