import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-frame-view',
  standalone: true,
  imports: [NgIf, ButtonModule, DialogModule],
  templateUrl: './frame-view.component.html',
  styleUrls: ['./frame-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrameViewComponent implements OnInit {
  link: string | null = null;
  visible: boolean = false;

  get safeLink() {
    return this.sanitize.bypassSecurityTrustResourceUrl(this.link ?? '');
  }

  constructor(private router: Router, private route: ActivatedRoute, private sanitize: DomSanitizer) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.link = data['link'];
      if (!this.link) this.router.navigate(['/home']);
    });
  }
}
